import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
// import { useAuthContext } from 'src/auth/hooks';
import { Button } from '@mui/material';
// import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';

import axios from 'src/utils/axios';
import { fToNow } from 'src/utils/format-time';

// import { useGetPatientNotifications } from 'src/api';
import { useLocales, useTranslate } from 'src/locales';

// ----------------------------------------------------------------------

export default function NotificationItem({ notification, handleClick }) {
  const { t } = useTranslate();
  // const { user } = useAuthContext();
  const { currentLang } = useLocales();
  // const { patientNotifications } = useGetPatientNotifications(user?.patient?._id);
  const curLangAr = currentLang.value === 'ar';
  //  eslint-disable-next-line
  const handleAccept = async () => {
    try {
      const { method, route, body } = notification.onAccept || {};

      if (!method || !['get', 'post', 'put', 'delete'].includes(method.toLowerCase())) {
        throw new Error('Invalid method provided in notification');
      }

      await axios[method.toLowerCase()](route, body);
      window.location.reload();
    } catch (error) {
      console.error('Error while accepting notification:', error);
    }
  };
  const renderAvatar = (
    <ListItemAvatar>
      {notification.avatarUrl ? (
        <Avatar src={notification.avatarUrl} sx={{ bgcolor: 'background.neutral' }} />
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            bgcolor: 'background.neutral',
          }}
        >
          <Box
            component="img"
            src={`/assets/icons/notification/${
              (notification.type === 'error' && 'error') ||
              (notification.type === 'created' && 'created') ||
              (notification.type === 'updated' && 'updated') ||
              (notification.type === 'request' && 'request') ||
              (notification.type === 'delivery' && 'ic_delivery')
            }.svg`}
            sx={{ width: 24, height: 24 }}
          />
        </Stack>
      )}
    </ListItemAvatar>
  );

  const renderText = (
    <ListItemText
      lang="ar"
      disableTypography
      primary={reader(curLangAr ? notification.title_arabic : notification.title)}
      secondary={
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            typography: 'caption',
            color: 'text.disabled',
            flexWrap: 'wrap',
            wordWrap: 'break-word',
          }}
          divider={
            <Box
              sx={{
                width: 2,
                height: 2,
                bgcolor: 'currentColor',
                mx: 0.5,
                borderRadius: '50%',
              }}
            />
          }
        >
          {fToNow(notification.created_at, curLangAr)}
          {t(notification.category)}
        </Stack>
      }
      primaryTypographyProps={{
        flexWrap: 'wrap',
        wordWrap: 'break-word',
        whiteSpace: 'wrap',
      }}
    />
  );

  const renderUnReadBadge = notification.isUnRead && (
    <Box
      sx={{
        top: 26,
        width: 8,
        height: 8,
        right: 20,
        borderRadius: '50%',
        bgcolor: 'info.main',
        position: 'absolute',
      }}
    />
  );
  const friendAction = (
    <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
      <Button onClick={handleAccept} size="small" variant="contained">
        {t('accept')}
      </Button>
      <Button size="small" variant="outlined">
        {t('decline')}
      </Button>
    </Stack>
  );

  // const beAmember = (
  //   <>
  //     {/* <ListItemText primary={reader(curLangAr ? notification.title_arabic : notification.title)} /> */}
  //     <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
  //       <Button size="small" variant="contained">
  //         Accept
  //       </Button>
  //       <Button size="small" variant="outlined">
  //         Decline
  //       </Button>
  //     </Stack>
  //   </>
  // );
  // const friendAction = (
  //   <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
  //     <Button size="small" variant="contained">
  //       Accept
  //     </Button>
  //     <Button size="small" variant="outlined">
  //       Decline
  //     </Button>
  //   </Stack>
  // );

  // const projectAction = (
  //   <Stack alignItems="flex-start">
  //     <Box
  //       sx={{
  //         p: 1.5,
  //         my: 1.5,
  //         borderRadius: 1.5,
  //         color: 'text.secondary',
  //         bgcolor: 'background.neutral',
  //       }}
  //     >
  //       {reader(
  //         `<p><strong>@Jaydon Frankie</strong> feedback by asking questions or just leave a note of appreciation.</p>`
  //       )}
  //     </Box>

  //     <Button size="small" variant="contained">
  //       Reply
  //     </Button>
  //   </Stack>
  // );

  // const fileAction = (
  //   <Stack
  //     spacing={1}
  //     direction="row"
  //     sx={{
  //       pl: 1,
  //       p: 1.5,
  //       mt: 1.5,
  //       borderRadius: 1.5,
  //       bgcolor: 'background.neutral',
  //     }}
  //   >
  //     <FileThumbnail
  //       file="http://localhost:8080/httpsdesign-suriname-2015.mp3"
  //       sx={{ width: 40, height: 40 }}
  //     />

  //     <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }} flexGrow={1} sx={{ minWidth: 0 }}>
  //       <ListItemText
  //         disableTypography
  //         primary={
  //           <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }} noWrap>
  //             design-suriname-2015.mp3
  //           </Typography>
  //         }
  //         secondary={
  //           <Stack
  //             direction="row"
  //             alignItems="center"
  //             sx={{ typography: 'caption', color: 'text.disabled' }}
  //             divider={
  //               <Box
  //                 sx={{
  //                   mx: 0.5,
  //                   width: 2,
  //                   height: 2,
  //                   borderRadius: '50%',
  //                   bgcolor: 'currentColor',
  //                 }}
  //               />
  //             }
  //           >
  //             <span>2.3 GB</span>
  //             <span>30 min ago</span>
  //           </Stack>
  //         }
  //       />

  //       <Button size="small" variant="outlined">
  //         Download
  //       </Button>
  //     </Stack>
  //   </Stack>
  // );

  // const tagsAction = (
  //   <Stack direction="row" spacing={0.75} flexWrap="wrap" sx={{ mt: 1.5 }}>
  //     <Label variant="outlined" color="info">
  //       Design
  //     </Label>
  //     <Label variant="outlined" color="warning">
  //       Dashboard
  //     </Label>
  //     <Label variant="outlined">Design system</Label>
  //   </Stack>
  // );

  // const paymentAction = (
  //   <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
  //     <Button size="small" variant="contained">
  //       Pay
  //     </Button>
  //     <Button size="small" variant="outlined">
  //       Decline
  //     </Button>
  //   </Stack>
  // );

  return (
    <ListItemButton
      key={notification._id}
      disableRipple
      onClick={() => handleClick(notification._id, notification.link)}
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        overflow: 'hidden',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
    >
      {renderUnReadBadge}

      {renderAvatar}

      {/* {patientNotifications?.map(
        (info) =>
          info.type === 'request' && (
            <>
              <ListItemText primary={reader(curLangAr ? info.title_arabic : info.title)} />
              <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
                <Button
                  size="small"
                  variant="contained"
                  // onClick={() => handleAddFamily(info?.patient)}
                >
                  Accept
                </Button>
                <Button size="small" variant="outlined">
                  Decline
                </Button>
              </Stack>
            </>
          )
      )} */}
      <Stack sx={{ flexWrap: 'wrap', wordWrap: 'break-word' }}>
        {renderText}
        {notification.type === 'invite' && notification.isUnRead && friendAction}
      </Stack>
    </ListItemButton>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.object,
  handleClick: PropTypes.func,
};

// ----------------------------------------------------------------------

function reader(data) {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: data }}
      sx={{
        mb: 0.5,
        '& p': { typography: 'body2', m: 0 },
        '& a': { color: 'inherit', textDecoration: 'none' },
        '& strong': { typography: 'subtitle2' },
      }}
    />
  );
}
