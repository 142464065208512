import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useTranslate } from 'src/locales';

// ----------------------------------------------------------------------

export default function ConfirmDialog({
  title,
  content,
  action,
  open,
  onClose,
  disabled,
  withoutCancel,
  ...other
}) {
  const { t } = useTranslate();
  // const { currentLang } = useLocales();
  // const curLangAr = currentLang.value === 'ar';
  return (
    <Dialog lang="ar" fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2, textTransform: 'capitalize' }}>{title}</DialogTitle>

      {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}

      <DialogActions sx={{ textTransform: 'capitalize' }}>
        {action}

        {!withoutCancel && (
          <Button variant="outlined" color="inherit" onClick={onClose} disabled={disabled}>
            {t('cancel')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  withoutCancel: PropTypes.bool,
  title: PropTypes.string,
};
