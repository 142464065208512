import { Container, Typography } from '@mui/material';

export default function TermsAndCondition() {
  return (
    <Container>
      <ul>
        <Typography variant="h3" gutterBottom sx={{ mt: 4 }}>
          Terms and Conditions of Use (For Medical Service Beneficiaries)
        </Typography>
        <li>
          <Typography variant="body1" paragraph>
            Please read the terms and conditions of use carefully, understanding all the clauses
            before signing your acceptance and before using the Hakeemna.com website or its
            associated applications or related platform pages.
          </Typography>
        </li>

        <Typography variant="h4" paragraph>
          Definition of Terms:
        </Typography>

        <li>
          <Typography variant="body1" paragraph>
            The first party is Hakeemna.com. When the term platform Hakeemna.com or Hakeemna.com
            Platform or system is mentioned in this section, it includes the website, Android or iOS
            applications, related websites, and the managers and employees working on the platform.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            The second party is the user and beneficiary of the medical services provided by medical
            practitioners and providers of services related to the health sector. The term user or
            beneficiary includes the patient and their family using the platform and visitors to the
            platform.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            The third party is the medical service provider, such as a medical practitioner, clinic,
            health center, radiology center, psychiatrist, medical supplies institution, or others
            who provide services or sell goods related to the health sector to the second party. The
            third party is any natural or legal person authorized by law in their country of
            residence to work directly or indirectly in the health sector.
          </Typography>
        </li>

        <Typography variant="h4" paragraph>
          Terms and Conditions of Use
        </Typography>

        <li>
          <Typography variant="body1" paragraph>
            Access or use of Hakeemna.com is only permitted for those who have agreed to the terms
            and conditions of use, have agreed to comply with the privacy policies of Hakeemna.com,
            and have committed to not committing any crime under the laws of electronic crimes in
            the Hashemite Kingdom of Jordan and in the country of residence.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            Hakeemna.com serves as a link to an integrated community of medical service providers
            and goods (the third party) and users of those services (the second party). The platform
            does not assume any responsibility for any contractual or legal relationship between the
            parties using the platform.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            By agreeing to the terms of use of Hakeemna.com, you acknowledge and agree that the
            contractual relationship is between you and the service provider, and that the platform
            is not part of or a partner in that relationship and is not responsible for any
            decision, action, consultation, or sale resulting from this relationship.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            Accessing, browsing, or using Hakeemna.com means you agree to comply with the terms and
            conditions of use and agree to adhere to the privacy policies and commit to not
            committing any crime under the laws of electronic crimes in the Hashemite Kingdom of
            Jordan and in the country of residence.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            By agreeing to the terms of use of Hakeemna.com, you acknowledge that the terms of use
            and privacy policy are both mandatory and complementary to each other.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            The user (the second and third parties) is legally responsible for the accuracy of the
            data they enter into Hakeemna.com and bears the legal consequences if they enter
            incorrect data or commit fraudulent actions to affect the platform or other users of the
            platform, whether the user is a beneficiary of services, a provider of those medical
            services, or a visitor to the platform.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            The user (the second or third party) undertakes that they have entered accurate and
            non-fraudulent data and that they bear the legal consequences and damages if they enter
            incorrect or fraudulent data, whether the damage has occurred to the first, second, or
            third party, or more than one party at the same time.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            The second and third parties acknowledge that they have provided Hakeemna with the data
            voluntarily and that the data is real and not fraudulent and that they bear the legal
            consequences and damages resulting from falsification of information and data.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            Hakeemna.com does not bear the consequences, results, or damages arising from any
            interpersonal, contractual, or legal relationship between the second and third parties.
            This also applies to relationships between users from the second party or damages
            resulting from a visitor to the platform.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            The relationship between the second and third parties is direct. You agree to consider
            it direct between you and that Hakeemna.com is not part of it and is not responsible for
            any decision, action, or damage resulting from this relationship.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            Remote consultation on Hakeemna.com between the second and third parties is to assist in
            reaching a better decision about a particular condition and is not the basis for making
            a decision about a health condition. Therefore, you (the second party) agree not to rely
            solely on remote consultation to undertake medical procedures or purchase medications.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            Hakeemna.com is not responsible in any way towards the second party or any user of the
            platform or visiting browser for any direct or indirect or judicial damages, regardless
            of the cause (loss of data, cessation of work, commercial losses from your inability to
            use the platform) and regardless of the responsible party and the cause of the loss.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            By agreeing to the terms and conditions of Hakeemna.com, you agree to bear (at your own
            expense) any damage or misuse resulting from you and causing harm to the platform or any
            other parties. You also bear, at your own expense, the costs of defending Hakeemna.com
            and its employees or other parties if the damage resulted from 1) misuse of data or 2)
            violation of third-party rights or user content 3) or committing a crime punishable
            under the electronic crimes law.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            Hakeemna.com does not contain medical advice or guidance. The platform may contain
            general information and should not be relied upon as a substitute for consulting with
            specialists about your health condition. Therefore, the second party should consult
            specialists about the subject in question.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            There are no guarantees or warranties regarding the quality or type of services provided
            by the parties registered on Hakeemna.com. Therefore, verifying the quality and type of
            services provided by medical service providers or any other party is your
            responsibility. In addition, Hakeemna.com is not responsible for any medical procedures,
            medical errors, or use of prescribed medical treatments through the platform or any
            health complications. All of the above is your personal responsibility with the third
            party.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            Note that there is no guarantee from Hakeemna.com about the availability of the service
            you have booked or contracted with the third party. Similarly, the service provider (the
            third party) has the right to refuse to perform a medical procedure for any medically
            acceptable reason or if the second party (patient) is unable to provide the financial
            payment for that procedure. Disputes between the second and third parties regarding the
            availability of the service and associated costs are outside the scope of the platform’s
            work and authority.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            When the second party (such as a patient) books an appointment (whether visiting the
            clinic or an online consultation) with a service provider (the third party), the second
            party should be present at the clinic ten minutes before the appointment time or
            connected online in the virtual meeting room five minutes before the appointment starts.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            Regarding non-compliance with attending appointments, the Hakeemna.com platform does not
            bear any costs for the absence of the second or third party from the appointment.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            The second party (patient) should agree with the third party (service provider) on the
            total treatment costs before starting the treatment.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            In some cases, there may be a change in the total cost of treatment for various reasons
            (such as complications). Therefore, you should consider the change in cost and ask the
            third party about the reasons for the change in treatment cost. In case of disputes due
            to changes in treatment cost, it is not within the scope or responsibility of
            Hakeemna.com.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            Hakeemna.com has the authority to terminate or suspend your access to the platform
            immediately and without prior notice for any reason, such as violation or breach of the
            terms of use or failure to comply with privacy laws or committing a crime under the
            electronic crimes law or any other law.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            Termination of access for the second party on Hakeemna.com does not mean the cessation
            of their responsibility to apply privacy laws and terms of use and does not mean release
            from responsibility for damages and compensation for their previous practices on the
            platform.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            Hakeemna.com is not responsible for any service, consultation, or product agreed upon
            between the second and third parties and is not responsible for damages resulting from
            it, whether direct or indirect. Additionally, parties should be cautious of links that
            may falsely use the name of Hakeemna.com or its trademarks. You should also be cautious
            and verify the credibility of the services and products offered.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            By agreeing to the terms of use of Hakeemna.com, you agree not to violate any applicable
            laws, such as commercial law, trademark law, or electronic crimes law or personal data
            protection law.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            You also agree not to use Hakeemna.com for purposes of defamation, deception, fraud,
            threats, attacks, inciting criminal acts, discrimination, infringement of patents,
            intellectual property rights, trademarks, copyrights, or acting on behalf of other
            parties for harmful purposes. You also undertake not to use the platform in a manner
            that contravenes Islamic law.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            By agreeing to the terms of use of Hakeemna.com, you undertake that you are over the age
            of 18. Additionally, the third party undertakes not to provide any service or sell any
            product to individuals under the legal age.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            Upon your approval of the details of using Hakeemna.com, if you are provided with your
            personal rights or your personal account or one of the individuals is hacked or you
            learn of violations committed by the second party or third party, you will secretly
            immediately inform the Hakeemna.com platform through the specialized specializations for
            this, so you will inform the judicial courts in those matters.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            The Hakeemna.com platform may build and the terms of use of Hakeemna Reason.com at any
            time and for any reason, so we encourage you to review this page periodically to obtain
            the latest information about the applications and features of use.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            The second party or third party may not use Hakeemna.com to violate any law or access
            the service or build to build the service or offer different against Hakeemna.com or
            different against the third party (service providers and medical supplies).
          </Typography>
        </li>
      </ul>
    </Container>
  );
}
